import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MikeWiseContact from "../components/mikewisecontactus"
import LynneMarlorContact from "../components/lynnemarlorcontactus"
import SamNathanContact from "../components/samnathancontactus"
import HeatherLoweContact from "../components/heatherlowecontactus"
import EricRouxContact from "../components/ericrouxcontactus"
import JohnHargravesContact from "../components/johnhargravescontactus"
import DougMehneContact from "../components/dougmehnecontactus"
import AJDeboleContact from "../components/ajdebolecontactus"
import NewsletterGen from "../components/newslettergen"


export default function WhoTemplate() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const [isOverlayVisibleLM, setIsOverlayVisibleLM] = useState(false)
  const [isOverlayVisibleSN, setIsOverlayVisibleSN] = useState(false)
  const [isOverlayVisibleHL, setIsOverlayVisibleHL] = useState(false)
  const [isOverlayVisibleER, setIsOverlayVisibleER] = useState(false)
  const [isOverlayVisibleJH, setIsOverlayVisibleJH] = useState(false)
  const [isOverlayVisibleDM, setIsOverlayVisibleDM] = useState(false)
  const [isOverlayVisibleAD, setIsOverlayVisibleAD] = useState(false)

  const handleToggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible)
  }		
  const handleToggleOverlayLM = () => {
    setIsOverlayVisibleLM(!isOverlayVisibleLM)
  }		
  const handleToggleOverlaySN = () => {
    setIsOverlayVisibleSN(!isOverlayVisibleSN)
  }		
  const handleToggleOverlayHL = () => {
    setIsOverlayVisibleHL(!isOverlayVisibleHL)
  }	
  const handleToggleOverlayER = () => {
    setIsOverlayVisibleER(!isOverlayVisibleER)
  }		
  const handleToggleOverlayJH = () => {
    setIsOverlayVisibleJH(!isOverlayVisibleJH)
  }		
  const handleToggleOverlayDM = () => {
    setIsOverlayVisibleDM(!isOverlayVisibleDM)
  }		
  const handleToggleOverlayAD = () => {
    setIsOverlayVisibleAD(!isOverlayVisibleAD)
  }		

  return (
    <Layout breadcrumbs={["Events"]}>
          <div className="carousel-container">
      <div className="group-5-about-us-events" >
      <div className="connecting-the-blockchain-together" >Join Our Events</div>
      <p className="about-us-carousel">Whether in-person or online, we hope you’ll join us for an upcoming event.<br /><br />Our in-person events are top-tier networking opportunities, where you’ll learn from trailblazers in the blockchain industry.<br /><br />Our online events include workshops, webinars, and panels that can be watched from anywhere—either live, or on-demand.<br /><br />Be inspired. Learn what’s new. And connect with our vibrant community. Join us!</p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701203905/events/thumbnail_header_Events_Page_s0bkmv.jpg" class="group-3" />
    </div>  
    <div className="container-noflex">
      <div className="group-events-header">
        <h1>2023-2024 Events Roadmap</h1>
        <h5>To see the roadmap for our planned events, <a href="../BBA 2024 Blockchain and Digital Assets Roadmap.pdf" target="_blank" title="BBA Road Map">click here</a>.</h5>
      </div>
       <div className="group-8">
          <div className="who-we-are-new">
             <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701459515/events/20230523_174443_1_o9ipox.png"  className="image-events" />
             <a href="https://www.meetup.com/boston-blockchain-association/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701459547/events/Vector_fd1vup.svg" className="image-events" /></a>
             <p>To join upcoming events, join our Meetup group, where all new events are posted.</p>       
             <a href="https://www.meetup.com/boston-blockchain-association/" target="_blank"><button className="who-we-are-new-volunteer-button"><b>See upcoming events</b></button></a>      
          </div>
          <div className="who-we-are-new">
             <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701460025/events/4Y1A0234_1_a1cjbw.png"  className="image-events" />
             <a href="https://www.crowdcast.io/@boston-blockchain" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701460062/events/crowdcasthq_logo_light_d0fcd55e5f_nx6q9u.png"  className="image-events" /></a>
             <p>To view past online events, they’re all archived on our Crowdcast page.</p> 
             <a href="https://www.crowdcast.io/@boston-blockchain" target="_blank"><button className="who-we-are-new-volunteer-button"><b>See upcoming events</b></button></a>                          
          </div>       
          <div className="who-we-are-new">
             <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701460175/events/4Y1A0169_1_qgxdib.png"  className="image-events" />
             <a href="https://www.eventbrite.com/o/boston-blockchain-association-55182169053" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701460193/events/Eventbrite_Logo_a7ci5q.png"  className="image-events" /></a>             
             <p>To register for a ticketed event, use our Eventbrite page.</p>  
             <a href="https://www.eventbrite.com/o/boston-blockchain-association-55182169053" target="_blank"><button className="who-we-are-new-volunteer-button"><b>See upcoming events</b></button></a>                                  
          </div>                
      </div>  
    </div>  
   
    <div className="container-noflex-events">
        <div className=" who-we-are-new-volunteer-cta-new">
          <div className=" who-we-are-new-volunteer-content-new">
            <h2>Join Our Newsletter</h2>
            <p>To get notified about all the blockchain events in Boston (BBA and beyond), sign up for our weekly newsletter.</p>
          </div>
          <div className="who-we-are-new-volunteer-img-hand">
            <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701526498/events/1000_F_361449961_i1QO8dNVVvwzGHdEFI1j7f67GbBWHZXq_1_xequcv.png" />
          </div>         
         </div>  
         <div className="newsletter-container-events">
      <h3 className="subscribe-to-the-newsletter">Subscribe to the Newsletter</h3>
	  <div className="subscribe-to-the-boston-blockchain-association-newsletter-and-get-notified-on-the-latest-blockchain-events-job-openings-opportunities-and-more" >Subscribe to the Boston Blockchain Association newsletter and get notified on the latest blockchain events, job openings, opportunities, and more. </div>
      <div className="newsletter-form-container">
	  <NewsletterGen />
	  </div>
    </div>         
    </div>    
      </Layout>
  )
}